import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  plumbersHero,
  plumbersSubHero,
  plumbersWhiteGlove,
  plumbersLargeFeatureA,
  plumbersTrusted,
  plumbersMultiSection,
  plumbersCTA,
  plumbersTestimonials,
  // plumbersArticles,
  // plumbersFaqs,
  plumbersCar,
} from "../../../data/plumbers-data";
import { autoBTSlides, heroSuccess } from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";

import heroBg from "../../../images/hero-images/plumbers-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "plumbers-white-glove.png";

const PlumbersService = () => {
  return (
    <Layout>
      <SEO
        title="Plumbing Company Management Software & POS Services | SpotOn"
        description="SpotOn's POS will help your plumbing business benefit from flexible payment options, online appointment booking, & marketing programs to attract and grow your business. Get a demo!"
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/professional-services/plumbers/"
      />
      <BHero sectionData={plumbersHero} heroBg="plumbers-hero.png" />
      <NoVisualsCustom sectionData={plumbersSubHero} simple />
      <WhiteGlove
        sectionData={plumbersWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={plumbersLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={plumbersTrusted} />
      <LargeFeatures
        sectionData={plumbersMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={plumbersCTA} />
      <SuccessStories sectionData={heroSuccess} slides={autoBTSlides} />
      <TestmonialReviews sectionData={plumbersTestimonials} />
      <BusinessTypes sectionData={plumbersCar} />
      {/* <Faqs sectionData={plumbersFaqs} /> */}
    </Layout>
  );
};

export default PlumbersService;
